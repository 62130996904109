import * as React from "react";

import classNames from "classnames";

import Header from "../components/header";
import Footer from "../components/footer";
import Gallery from "../components/gallery";

import pic1 from "../assets/images/reupholstery/1.jpg";
import pic2 from "../assets/images/reupholstery/2.jpeg";
import pic3 from "../assets/images/reupholstery/3.jpg";
import pic4 from "../assets/images/reupholstery/4.jpeg";

import GalleryHeader from "../components/galleryheader";

const galleryColumns = [
  [pic3, pic1],
  [pic2],
  [pic4]
];

const ReupholsteryPage = () => (
  <React.Fragment>
    <Header />
      <GalleryHeader title="Reupholstery" />
      <Gallery columns={galleryColumns} />
    <Footer />
  </React.Fragment>
);

export default ReupholsteryPage;
