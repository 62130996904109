import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "./gallery.module.css";
import classNames from "classnames";

const renderColumns = (columns) => {
  return columns.map(column => {
    return (
      <div className={classNames(styles.galleryColumn)}>
        {column.map(image => (<img src={image} alt="Window Treatment" />))}
      </div>
      )
  });
}

const Gallery = ({ columns }) => (
  <div className={classNames(styles.gallery, "container")}>
    <div className="row">
      <div className="col-12">
        <div className={classNames(styles.galleryContainer)}>
          { renderColumns(columns) }
        </div>
      </div>
    </div>
  </div>
);

export default Gallery;
