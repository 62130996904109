import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "./galleryheader.module.css";
import classNames from "classnames";


const GalleryHeader = ({ title }) => (
  <div className={classNames(styles.galleryHeader, "container-fluid")}>
    <div className="row">
      <div className="col-12 text-center">
        <h1>{title}</h1>
        <hr className={classNames(styles.hr)} />
      </div>
    </div>
  </div>
);

export default GalleryHeader;
